import React, {useState, useEffect} from 'react';
import axios from 'axios';
import TableCell from "@mui/material/TableCell";
import SideBar from "../component/SideBar";

import {TableRide} from "../component/TableRide";

import { Button} from 'rsuite';
import {MuiThemeProvider, createTheme} from "@material-ui/core/styles";
import {TableInterval} from "../component/TableInterval";

const rider_list_url = "https://api.holoapp.tech/rides/brta/get-brta-ride-list";
const refresh_url = "https://api.holoapp.tech/accounts/refresh";
const logout_url = "https://api.holoapp.tech/accounts/logout";
const access = localStorage.getItem("accessToken");
// console.log("access", access)
const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${access}`,
};
const theme = createTheme({
    palette: {
        primary: {
            main: "#121212",
        },
    },
});

const SearchForm = ({onSearchResults}) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [registration, setRegistration] = useState('');
    const [name, setName] = useState('');
    const [date, setDate] = useState('');
    const [errors, setErrors] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [noResultsMessage, setNoResultsMessage] = useState('');

    const handleSearch = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.get(rider_list_url, {
                params: {
                    date: date,
                    name: name,
                    phone: phoneNumber,
                    reg: registration
                },
                headers: headers
            });
            const searchResults = response.data;
            if (searchResults.length === 0) {
                setNoResultsMessage('No matching results found.');
            } else {
                setNoResultsMessage('');
                onSearchResults(searchResults);
                console.log("checking result", searchResults)
            }

        } catch (error) {
            console.error('Error fetching search data:', error);
            if (error.response.status === 401) {
                console.log(error.response);
                const refresh = localStorage.getItem("refreshToken");
                axios.post(refresh_url, refresh).then((request) => {
                    if (request.status === 200) {
                        console.log("refresh valid");
                        localStorage.setItem("accessToken", request.data["access"]);
                    } else if (request.status === 401) {
                        console.log("invalid so logout");
                        axios.post(logout_url, headers).then((response) => {
                            localStorage.setItem("accessToken", "");
                            localStorage.setItem("refreshToken", "");
                            console.log("access", response.data["access"]);
                            console.log("refresh", response.data["refresh"]);
                            setErrors(true);
                            setErrorMsg(error.response.data.detail);
                            window.location.href = "/login";
                        });
                    }

                });

            }
        }
    };

    return (
        <div>
            <SideBar/>
            <form onSubmit={handleSearch}>
                {/* Input fields for search parameters */}
                <input
                    type="text"
                    style={{margin: 10}}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Phone Number"
                />
                <input
                    type="text"
                    style={{margin: 10}}
                    value={registration}
                    onChange={(e) => setRegistration(e.target.value)}
                    placeholder="Registration"
                />
                <input
                    type="text"
                    style={{margin: 10}}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                />
                <input
                    type="date"
                    style={{margin: 10}}
                    data-date-format='YYYY-MM-DD'
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    placeholder="Date"
                />
               <Button appearance="ghost" type="submit" style={{margin: 10}}>Search</Button>
            </form>
            {noResultsMessage && <p>{noResultsMessage}</p>}
        </div>
    );
};

const Ongoing = () => {
    const [fullList, setFullList] = useState([]);
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        // Fetch the full list using the first API
        axios.get(rider_list_url, {
            headers: headers,
        })
            .then((response) => setFullList(response.data))
            .catch((error) => console.error('Error fetching full list:', error));
    }, []);

    const handleSearchResults = (results) => {
        setSearchResults(results);
    };

    return (
        <div>
            <SearchForm onSearchResults={handleSearchResults}/>

            {searchResults.length > 0 ? (
                <TableInterval data={searchResults}/>
            ) : (
               <TableInterval data={fullList}/>
            )}

        </div>
    );
};

export default Ongoing;

