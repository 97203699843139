import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";
import axios from "axios";
import {
  MapContainer,
  TileLayer,
} from "react-leaflet";
// import userIcon from "leaflet/dist/images/user.png";
// import riderIcon from "leaflet/dist/images/rider.png";
const ride_details_url ="https://api.holoapp.tech/rides/brta/get-brta-ride-complaint-list";

const access = localStorage.getItem("accessToken");

export const ComplainTable = ({ riders }) => {
  const [ride_id, setRideId] = useState("");

  const handleCellClick = (id) => {
    console.log("after id on click", id);

    console.log("calling");
    axios
      .get(ride_details_url, {
        params: { serial: id },
        headers: {
          Authorization: "Bearer " + access,
        },
      })
      .then((response) => {
        console.log("fggfg", response.status, response.data);
        if (response.status === 200) {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      style={{
        height: 800,
        width: "100%",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <CssBaseline />
      <div
        style={{
          margin: "20px",
          padding: "20px",
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="simple table">
            <TableHead>
              <TableRow>
              <TableCell align="center">Ride ID</TableCell>
              <TableCell align="center">Complaint</TableCell>
              <TableCell align="center">complainer</TableCell>
              <TableCell align="center">Complaint Against</TableCell>
              <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {riders.map((row) => {
                let resolve;
                resolve = row.is_resolved.toString() == 'true' ? ("Resolved"):("Not Resolved");
                return (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">{row.ride_id}</TableCell>
                      <TableCell align="center">{row.complaint}</TableCell>
                      <TableCell align="center">{row.account.name} / {row.account.phone}</TableCell>
                      <TableCell align="center">{row.against.name} / {row.against.phone}</TableCell>
                      <TableCell align="center">{resolve}</TableCell>
                    </TableRow>
                  
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div
        style={{
          margin: "20px",
          padding: "20px",
        }}
      >
      </div>
    </div>
  );
};