import "./App.css";

import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import 'rsuite/dist/rsuite.min.css';
import MapOngoing from "./pages/Ongoing";
import Completed from "./pages/Completed";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Ongoing from "./pages/Ongoing";
import Chalok from "./pages/Chalok";
import Jatri from "./pages/Jatri";
import Complains from "./pages/Complains";
import Alert from "./pages/Alert";
import RideSummary from "./pages/RideSummary";

function App() {
  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={3}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/completed" element={<Completed />} />
              <Route path="/ongoing" element={<Ongoing />} />
              <Route path="/chalok" element={<Chalok />} />  
              <Route path="/complain" element={<Complains />} />  
              <Route path="/alert" element={<Alert />} />  
              <Route path="/jatri" element={<Jatri />} />
              <Route path="/summary" element={<RideSummary/>}/>
            </Routes>
          </BrowserRouter>
        </Grid>
      </Grid>
    </>
  );
}

export default App;
