import React, { useState} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import {Paper} from "@mui/material";
import axios from "axios";

const p = "Please click an ID to see the details"
const ride_details_url =
    "https://api.holoapp.tech/rides/brta/get-brta-ride-details";

const access = localStorage.getItem("accessToken");

export const TableRide = ({data}) => {
    const [ride_id, setRideId] = useState("");
    const [price, setPrice] = useState("");
    const [distance, setDistance] = useState("");
    const [visible, setVisible] = useState("");
    const[userName, setUserName] = useState("")
    const[userNum, setUserNum] = useState("")
    const [riderName, setRiderName] = useState("")
    const [riderNum, setRiderNum] = useState("")

    const handleCellClick = async (id) => {
        console.log("after id on click", id);

        console.log("calling");
        await axios
            .get(ride_details_url, {
                params: {ride_id: id},
                headers: {
                    Authorization: "Bearer " + access,
                },
            })
            .then((response) => {

                console.log("details", response.status, response.data);
                if (response.status === 200) {
                    let rider_data = response.data
                    if (Object.values(rider_data).length !== 0) {
                        setPrice(rider_data.price)
                        setDistance(rider_data.max_fare_data.distance)
                        setVisible(rider_data.was_sticker_visible)
                        setUserName(rider_data.user.name)
                        setUserNum(rider_data.user.phone)
                        setRiderName(rider_data.rider.name)
                        setRiderNum(rider_data.rider.phone)
                    }

                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div
            style={{
                height: 800,
                width: "100%",
                display: "flex",
                flexDirection: "row",
            }}
        >
            <CssBaseline/>
            <div
                style={{
                    margin: "20px",
                    padding: "20px",
                }}
            >
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 500}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="right">Rider ID</TableCell>
                                <TableCell align="right">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row) => {
                                return (
                                    // row.status === "completed" && (
                                    <TableRow
                                        key={row.id}
                                        sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                    >
                                        <TableCell
                                            align="right"
                                            onClick={() => {

                                                console.log(row.status);
                                                setRideId(row.id);

                                                handleCellClick(row.id);
                                            }}
                                        >
                                            {row.id}
                                        </TableCell>
                                        <TableCell align="right">{row.status}</TableCell>
                                    </TableRow>

                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div>
                {ride_id ? <div>
                        {<div style={{
                            border: "2px solid red", padding: 60, marginTop: 40, clear: 'both',
                            display: 'inline-block',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap'
                        }}><span>Price: {price}</span><br/>
                            <span>Distance: {distance}</span>
<br/>
                            <span>Rider Name: {riderName}</span>
                            <br/>
                            <span>Rider Number: {riderNum}</span><br/>
                            <span>User Name: {userName}</span><br/>
                            <span>User Num: {userNum}</span><br/>
                            <span>Was Sticker Visible: {visible === true ?
                                <p style={{color: 'blue', fontWeight: 'bold'}}>Yes</p> :
                                <p style={{color: 'red', fontWeight: 'bold'}}>No</p>}</span>

                        </div>}</div>
                    :
                    <Typography
                        variant="h6"
                        component="h6"
                        align="center"
                        width="300px"
                        style={{
                            wordWrap: "break-word",
                            margin: "10px",
                            padding: "10px",
                        }}
                    >
                        {p}
                    </Typography>}
            </div>
        </div>
    );
};
