import React from "react";
import Box from "@mui/material/Box";
// import { useHistory } from 'react-router-dom';
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import {Link} from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import {Button} from 'rsuite';
import axios from "axios";
import {useState} from "react";

const drawerWidth = 150;
const refresh_url = "https://api.holoapp.tech/accounts/refresh";
const logout_url = "https://api.holoapp.tech/accounts/logout";
const access = localStorage.getItem("accessToken");
const refresh = localStorage.getItem("refreshToken");
console.log("access", access)
console.log("refresh", refresh)
const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${access}`,
};
export default function SideBar() {

  const [errors, setErrors] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");


  // const history = useHistory();

  const handleLogout = () => {
      // Clear access and refresh tokens from local storage
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');

      // Optionally, send a request to the server to invalidate the refresh token
      // This step depends on your server-side implementation

      // Redirect to the login page or the desired page after logout
     window.location.href = "/login";
  }


    return (
        <Box sx={{display: "flex"}}>
            <CssBaseline/>
            <AppBar
                position="fixed"
                sx={{width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`}}
                style={{backgroundColor: "#D50000"}}
            >
                <Toolbar/>
<Button  active appearance="primary" onClick={handleLogout} style={{ width: '100px', position: 'absolute', right: '20px', backgroundColor: 'black'}}>Logout</Button>
            </AppBar>

            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <Toolbar/>
                <Divider/>
                <List>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <Link to="/ongoing">
                                <ListItemText primary="Ongoing" style={{color: "#000"}}/>
                            </Link>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <Link to="/completed">
                                <ListItemText primary="Completed" style={{color: "#000"}}/>
                            </Link>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <Link to="/chalok">
                                <ListItemText primary="Chalok" style={{color: "#000"}}/>
                            </Link>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <Link to="/jatri">
                                <ListItemText primary="Jatri" style={{color: "#000"}}/>
                            </Link>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <Link to="/complain">
                                <ListItemText primary="Complains" style={{color: "#000"}}/>
                            </Link>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <Link to="/alert">
                                <ListItemText primary="Alert" style={{color: "#000"}}/>
                            </Link>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <Link to="/summary">
                                <ListItemText primary="Ride Summary" style={{color: "#000"}}/>
                            </Link>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{flexGrow: 1, bgcolor: "background.default", p: 3}}
            >
                <Toolbar/>
            </Box>
        </Box>
    );
}
